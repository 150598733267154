#admin-bg {
  background-image: url("assets/img/Admin-BG.png");
  min-height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;

  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
#sme-login-bg {
  background-image: url("assets/img/smeLoginbg.png");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.body {
  font-family: "Kanit", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 926px) {
  #admin-bg {
    width: 50%;
  }
}

.segment {
  height: 100vh;
}
