#admin-bg {
  background-image: url("assets/img/Admin-BG.png");
  min-height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;

  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
#sme-login-bg-container {
  background-image: url("assets/img/smeLoginbg.png");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  padding: 250px 90px 110px 90px;
}
#right-login-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 230px 90px 110px 90px;
}
.body {
  font-family: "Kanit", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1024px;
}
#card:hover {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1024px) {
  #admin-bg {
    width: 50%;
  }
  #sme-login-bg-container {
    padding: 240px 10px 110px 10px;
  }
  #right-login-container {
    padding: 230px 20px 110px 20px;
  }
}
